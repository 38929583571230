import React from "react"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { BannerContainer, BannerWrapper } from "../global/common/containers"
import { MainTitle } from "../global/common/text"
import { greenBox, fadedGreenBox } from "../global/common/ornaments"

import { SocialMedia } from "../global/SocialMedia"
import { Reviews } from "../global/Reviews"
import { Button } from "../global/atoms/Button"

import Nav from "../global/nav/Nav"
import ButtonGroup from "../global/atoms/ButtonGroup"
import SmallLogos from "../global/common/logos"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"


const ContentColumn = styled.div`
  max-width: 100%;
  width: 680px;
  padding-right: 90px;

  @media ${minD.laptopL} {
    min-height: 650px;
  }

  @media ${maxD.laptopL} {
    width: 540px;
    padding-right: 70px;
  }

  @media ${maxD.laptop} {
    width: 480px;
  }

  @media ${maxD.mobileL} {
    padding-right: 0;
  }
`

const ContentColumnInner = styled.div`
  padding-left: 0px;

  @media ${minD.laptop} {
    min-height: 285px;
  }

  @media ${minD.desktop} {
    min-height: 340px;
  }
`

const ImagesColumn = styled.div`
  position: relative;
  width: calc(100% - 680px);

  @media ${maxD.laptopL} {
    width: calc(100% - 540px);
  }

  @media ${maxD.laptop} {
    width: calc(100% - 480px);
  }

  @media ${maxD.tablet} {
    width: 100%;
  }
`

const bannerImageContainer = css`
  position: absolute;

  max-width: 100%;
  width: 408px;
  height: 510px;

  @media ${maxD.desktop} {
    width: calc(408px * 0.9);
    height: calc(510px * 0.9);
  }

  @media ${maxD.tablet} {
    position: relative;
  }

  @media ${maxD.mobileL} {
    width: 100%;
    height: 350px;
  }
`

const BottomImageContainer = styled.div`
  ${bannerImageContainer}
  left: 0;
  bottom: 50px;

  z-index: 2;

  ${greenBox}

  &::after {
    top: -35px;
    left: 180px;

    @media ${maxD.laptopL} {
      left: 60px;
    }
  }

  @media ${maxD.laptop} {
    display: none;
  }
`

const TopImageContainer = styled.div`
  ${bannerImageContainer}
  right: 0;
  bottom: 210px;

  ${fadedGreenBox}

  @media ${maxD.laptop} {
    bottom: 0px;
    left: 0;
    width: 100%;
  }

  @media ${maxD.tabletL} {
    display: none;
  }

  &::after {
    left: 130px;
    bottom: 15px;

    @media ${maxD.laptopL} {
      left: 240px;
    }

    @media ${maxD.laptop} {
      left: 70px;
      bottom: 60px;
  }
`

const BannerImage = styled.img `
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;

  @media ${maxD.laptop} {
    inset: auto;
    height: 80%;
    width: 100%;
    left: 0;
    right: 0;
    object-position: top;
  }
`

const SocialMediaContainer = styled.div`
  @media ${maxD.tabletL} {
    display: none;
  }
`

function HomeHeader({ pageContext, data }) {
  const header = {
    gray: data.homeBannerTitle.textGray,
    green: data.homeBannerTitle.textGreen,
  }

  const buttons = [
    {
      title: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.title,
      url: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.url,
      target: data.homeBannerButtons.homeBannerButton1 && data.homeBannerButtons.homeBannerButton1.target,
    },
    {
      title: data.homeBannerButtons.homeBannerButton2.title,
      url: data.homeBannerButtons.homeBannerButton2.url,
      target: data.homeBannerButtons.homeBannerButton2.target,
    },
  ]
  const images = {
    top: {
      url: getImageBySrcSetAndSize(data.homeBannerImages.homeBannerImage1.srcSet, 'medium') ? getImageBySrcSetAndSize(data.homeBannerImages.homeBannerImage1.srcSet, 'medium') : optimizedPath(data.homeBannerImages.homeBannerImage1.sourceUrl),
      srcSet: getRangedSrcSet(data.homeBannerImages.homeBannerImage1.srcSet, 'medium'),
      alt: data.homeBannerImages.homeBannerImage1.altText,
      altPl: data.homeBannerImages.homeBannerImage1.atttachement_pl?.altTextPl
    },
    bottom: {
      url: getImageBySrcSetAndSize(data.homeBannerImages.homeBannerImage2.srcSet, 'medium') ? getImageBySrcSetAndSize(data.homeBannerImages.homeBannerImage2.srcSet, 'medium') : optimizedPath(data.homeBannerImages.homeBannerImage2.sourceUrl),
      srcSet: getRangedSrcSet(data.homeBannerImages.homeBannerImage2.srcSet, 'medium'),
      alt: data.homeBannerImages.homeBannerImage2.altText,
      altPl: data.homeBannerImages.homeBannerImage2.atttachement_pl?.altTextPl
    },
  }

  return (
    <BannerContainer>
      <Nav pageContext={pageContext} />
      <BannerWrapper relative flex alignItems="fe">
        <ContentColumn>
          <ContentColumnInner>
            <MainTitle as="h1" style={{ lineHeight: "1.5", marginTop: "30px" }}>
              {`${header.gray} `}
              <br />
              <span>{header.green}</span>
            </MainTitle>
            <ButtonGroup>
              {
                buttons[0].title ? <Button to={buttons[0].url}>{buttons[0].title}</Button> : ''
              }
              <Button to={buttons[1].url} theme="orange">
                {buttons[1].title}
              </Button>
            </ButtonGroup>
          </ContentColumnInner>
          <Reviews />
          <SmallLogos logos={data.homeBannerLogos} />
        </ContentColumn>
        <ImagesColumn>
          <TopImageContainer>
            <BannerImage
              rel="preload"
              src={images.top.url}
              srcSet={images.top.srcSet}
              alt={(SiteLanguage() !== "en" && images.top.altPl) ? images.top.altPl : images.top.alt}
            />
          </TopImageContainer>
          <BottomImageContainer>
            <BannerImage
              rel="preload"
              src={images.bottom.url}
              srcSet={images.top.srcSet}
              alt={(SiteLanguage() !== "en" && images.bottom.altPl) ? images.bottom.altPl : images.bottom.alt}
            />
          </BottomImageContainer>
        </ImagesColumn>
        <SocialMediaContainer>
          <SocialMedia theme="green" style={{ position: "absolute" }} />
        </SocialMediaContainer>
      </BannerWrapper>
    </BannerContainer>
  )
}

export { HomeHeader }
