import React from "react"
import List from "../global/List"

const SimpleSteps = ({ data }) => {
  const title = data.homeStepsTitle

  const list = data.homeStepsList

  const button = {
    title: data.homeStepsButton?.title,
    url: data.homeStepsButton?.url,
    target: data.homeStepsButton?.target,
  }

  return <List title={title} list={list} button={button} />
}

export { SimpleSteps }
